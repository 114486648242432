import * as Sentry from "@sentry/browser";
import { useFlag } from "@unleash/proxy-client-react";
import { useCallback, useState } from "react";

import { OrderInfo, OrderInfoFormValues } from "@web/common";
import FormLayout from "@web/common/layouts/FormLayout";
import { BasketEntry } from "@web/common/models/basket";
import { OrderRequisition } from "@web/common/network/model";
import { Money } from "@web/models";
import { Loading } from "@web/ui";
import { WithRequiredProperty } from "@web/utils";

import routes from "src/config/routes";
import { useApproveRequisitionMutation } from "src/hooks/useApproveRequisitionMutation";
import { useGatherOut } from "src/hooks/useGatherOut";
import { useUpdateRequisitionMutation } from "src/hooks/useUpdateRequisitionMutation";
import { useVesselConfigurationQuery } from "src/hooks/useVesselConfiguration";

import { toApproveRequisitionItem, toEditItemList } from "./utils";

interface Props {
  lineItems: BasketEntry[];
  requisitionId: string;
  orderRequisition: OrderRequisition;
  totalPrice: Money;
}

export const RequisitionApproval = ({
  lineItems,
  requisitionId,
  orderRequisition,
  totalPrice,
}: Props) => {
  const vesselConfigurationQuery = useVesselConfigurationQuery(orderRequisition.vessel.id);
  const { mutate, isPending } = useApproveRequisitionMutation();
  const items = toEditItemList(lineItems);
  const hasEditedItems = items.length > 0;
  const hasSelectSupplierFeature = useFlag("select-supplier");
  const { isGatherOutAvailable, startGatherOut, isGatherOutStarted } = useGatherOut({
    vesselId: orderRequisition.vessel.id,
    gatherOutFlow: "EDIT_REQUISITION",
    orderId: requisitionId,
    submitReturnToPath: `${routes.orderDetails}/${requisitionId}/approve`,
    cancelReturnToPath: `${routes.orderDetails}/${requisitionId}/approve`,
  });

  const [formValues, setFormValues] = useState<
    WithRequiredProperty<OrderInfoFormValues, "subject"> | undefined
  >();

  const handleFormValuesChanged = useCallback(
    (formValues: WithRequiredProperty<OrderInfoFormValues, "subject">) => {
      setFormValues(formValues);
    },
    []
  );

  const { mutate: updateRequisition, isPending: isUpdateRequisitionPending } =
    useUpdateRequisitionMutation({
      onSettled: () => {
        // Saving requisition is not crucial to the GatherOut - worst case
        // user is going to lose some changes made to the form.
        // In case there are any issues, just proceed with starting the GatherOut.
        startGatherOut();
      },
      onError: (error: unknown) => {
        // Log the error to Sentry anyway, so we know something is wrong
        Sentry.captureException(error);
      },
    });

  const handleStartGatherOut = useCallback(() => {
    // Saving requisition is not crucial to the GatherOut - worst case
    // user is going to lose some changes made to the form.
    // In case there are any issues, just proceed with starting the GatherOut.
    if (!formValues || !formValues.deliveryDate) {
      startGatherOut();
      return;
    }

    updateRequisition({
      requisitionId,
      requestBody: {
        agent: formValues.agentInformation || {
          firstName: "",
          lastName: "",
          company: "",
          email: "",
          phoneNumber: "",
        },
        deliveryDate: formValues.deliveryDate,
        orderNotes: formValues.orderNotes,
        customerOrderId: formValues.customerOrderId,
        storageLabel: formValues.storageLabel,
        invoiceAccountId: formValues.invoiceAccountId,
        consolidated: formValues.consolidated ?? orderRequisition.consolidated,
        warehouseId: formValues.warehouseId ?? orderRequisition.warehouse?.id,
        subject: formValues.subject,
      },
    });
  }, [
    formValues,
    orderRequisition.consolidated,
    orderRequisition.warehouse?.id,
    requisitionId,
    startGatherOut,
    updateRequisition,
  ]);

  const submitOrderHandler = useCallback(
    (orderSummary: OrderInfoFormValues) => {
      // This guard is only to make the compiler happy, without major refactor of OrderInfo component.
      // In reality this will never happen thanks to the validation set up in OrderInfo component.
      if (!orderSummary.deliveryDate) {
        throw Error("Delivery date is not defined");
      }

      const result = {
        id: requisitionId,
        requestBody: {
          agent: orderSummary.agentInformation || {
            firstName: "",
            lastName: "",
            company: "",
            email: "",
            phoneNumber: "",
          },
          deliveryDate: orderSummary.deliveryDate,
          orderNotes: orderSummary.orderNotes,
          consolidated: orderSummary.consolidated ?? orderRequisition.consolidated,
          storageLabel: orderSummary.storageLabel,
          customerOrderId: orderSummary.customerOrderId,
          items: hasEditedItems
            ? items
            : orderRequisition.items.map((item) => toApproveRequisitionItem(item)),
          // Extra Items are not viewable or editable in Approval process in the Main App
          extraItems: orderRequisition.extraItems,
          invoiceAccountId: orderSummary.invoiceAccountId,
          warehouseId: orderSummary.warehouseId ?? orderRequisition.warehouse?.id,
          subject: orderSummary.subject,
        },
      };

      if (orderRequisition.consolidated && !result.requestBody.warehouseId) {
        throw Error("WarehouseId is not defined");
      }

      mutate(result);
    },
    [
      hasEditedItems,
      items,
      mutate,
      orderRequisition.consolidated,
      orderRequisition.extraItems,
      orderRequisition.items,
      orderRequisition?.warehouse?.id,
      requisitionId,
    ]
  );

  if (vesselConfigurationQuery.isPending || vesselConfigurationQuery.isFetching) {
    return <Loading />;
  }

  if (!vesselConfigurationQuery.data) {
    return null;
  }

  return (
    <FormLayout
      isGatherOutStarted={isGatherOutStarted || isUpdateRequisitionPending}
      startGatherOut={handleStartGatherOut}
      isGatherOutAvailable={isGatherOutAvailable}
      goBackPath={`${routes.orderDetails}/${requisitionId}`}
    >
      <OrderInfo
        creationMode="REQUISITION_APPROVAL"
        grandTotal={hasEditedItems ? totalPrice : orderRequisition.totalGrossAmount}
        invoiceAccounts={vesselConfigurationQuery.data.vessel.invoiceAccounts}
        loading={isPending}
        isSubmitDisabled={isGatherOutStarted}
        nrLineItems={hasEditedItems ? items.length : orderRequisition.items.length}
        nrRfqItems={0}
        nrExtraItems={orderRequisition.extraItems.length}
        orderRequisition={orderRequisition}
        port={orderRequisition.port}
        submitForm={submitOrderHandler}
        vessel={orderRequisition.vessel}
        warehouses={vesselConfigurationQuery.data.warehouses ?? []}
        withOrderComment
        supplier={hasSelectSupplierFeature ? orderRequisition.supplierInformation : undefined}
        defaultAgentInformation={orderRequisition.agentInformation}
        onFormValuesChanged={handleFormValuesChanged}
      />
    </FormLayout>
  );
};
