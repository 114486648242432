import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { RfqLineItem } from "@web/common/components/RfqLineItem";
import { OrderRequisition } from "@web/common/network/model";
import { Heading, Label } from "@web/ui";
import { formatMoney } from "@web/utils";

import RequestTable from "../../../components/tables/RequestTable";
import RequisitionLayout from "../../../layouts/RequisitionLayout";
import { clearGatherBasketEntries, useAppDispatch } from "../../../store";

type RequisitionDetailsProps = {
  requisition: OrderRequisition;
};

const RequisitionDetails: React.FC<RequisitionDetailsProps> = ({ requisition }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(clearGatherBasketEntries());
  }, [dispatch]);

  return (
    <RequisitionLayout requisition={requisition}>
      <Heading size="100">{requisition.subject}</Heading>
      <hr className="my-4" />

      <div className="flex flex-row w-full items-start justify-between">
        <Heading size="300">{t("pages.requisition.requestedItems")}</Heading>
        {!requisition.isRfqRequisition && (
          <div className="flex flex-row items-center">
            <Label size="200">{t("pages.requisition.totalGrossAmount")}</Label>
            <Heading size="300" className="pl-5">
              {formatMoney(requisition.totalGrossAmount)}
            </Heading>
          </div>
        )}
      </div>
      {requisition.isRfqRequisition ? (
        requisition.rfqItems?.map((item) => {
          return (
            <RfqLineItem isRemoved={false} key={item.id} quantity={item.quantity} rfqfItem={item} />
          );
        })
      ) : (
        <RequestTable requisition={requisition} />
      )}
    </RequisitionLayout>
  );
};

export default RequisitionDetails;
