import { DotsVerticalIcon, PaperClipIcon } from "@heroicons/react/outline";
import { QuestionMarkCircleIcon, UserCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { OrderRequisition } from "@web/common/network";
import { OrderReqService } from "@web/common/services";
import {
  Dropdown,
  DropdownItem,
  IconButton,
  Label,
  Paragraph,
  RegularDropdownItem,
  Tooltip,
  renderLongStringWithOptionalTooltip,
} from "@web/ui";
import { extractFromISODateString, formatShortString } from "@web/utils";

import Anchor from "../../../icons/Anchor.svg";
import { Pill } from "../../Pill";

interface Props {
  order: OrderRequisition;
  getUrl: (order: OrderRequisition) => string;
  openDeleteDraftModal?: (draftId: string) => void;
}

export const OrdersTableRow: React.FC<Props> = (props: Props) => {
  const { order, getUrl, openDeleteDraftModal } = props;
  const { attachments = [] } = order;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRowClick = () => {
    navigate(getUrl(order));
  };

  const dropdownMenuItems: DropdownItem[] = [
    {
      key: "deleteDraft",
      renderComponent: () => (
        <RegularDropdownItem
          label="Delete Draft"
          variant="danger"
          LeadingIcon={XCircleIcon}
          onClick={() => {
            if (openDeleteDraftModal) {
              openDeleteDraftModal(order.id);
            }
          }}
          data-testid="editOrderDropdownItem"
        />
      ),
    },
  ];

  return (
    <tr
      className="bg-neutral_0 cursor-pointer"
      onClick={handleRowClick}
      data-testid="ordersTable_row"
    >
      <td className="p-2 rounded-l-lg border-1 border-r-0">
        <div className="flex flex-col whitespace-nowrap truncate">
          <Label size="200" className="cursor-pointer">
            {renderLongStringWithOptionalTooltip(order.subject || "", 24, formatShortString)}
          </Label>
        </div>
      </td>
      <td className="p-2 border-y-1">
        <div className="flex flex-col whitespace-nowrap truncate">
          <Label size="200" className="cursor-pointer">
            {order.customerOrderId}
          </Label>
        </div>
      </td>
      <td className="p-2 border-y-1">
        <Paragraph size="200">{order.vessel.name}</Paragraph>
        {!!order.requesterInformation && (
          <div className="flex items-center pt-1">
            <div className="w-4 h-4">
              <UserCircleIcon className="text-neutral_700" />
            </div>
            <Paragraph size="200" color="text-neutral_700" className="ml-2 max-w-[150px] truncate">
              {order.requesterInformation.name}
            </Paragraph>
          </div>
        )}
      </td>
      <td className="p-2 border-y-1">
        <div className="flex flex-col">
          <div className="flex">
            <img src={Anchor} alt="anchor icon" className="w-3 mr-1" />
            <Paragraph size="200" color="text-neutral_700">
              {order.port.locationCode} {order.port.name}
            </Paragraph>
          </div>
          <div className="flex pt-1">
            {!!order.deliveryDate && (
              <Paragraph size="200" color="text-neutral_700">
                {extractFromISODateString(order.deliveryDate, "justDate")}
              </Paragraph>
            )}
          </div>
        </div>
      </td>
      <td className="p-2 border-y-1">
        <Pill className="cursor-pointer" status={order.status} />
      </td>
      <td className="border-y-1 text-right pr-6">
        <div className="cursor-pointer flex justify-end">
          {order.isRfqRequisition ||
          order.status === "SUPPLIER_QUOTE_PENDING" ||
          order.status === "SUPPLIER_QUOTE_ACKNOWLEDGED" ? (
            <Tooltip tooltipText={t("common.rfqTooltip")}>
              <div className="py-1 px-2 mt-1 rounded-md border border-neutral_300 flex">
                <QuestionMarkCircleIcon className="h-4 w-4 mr-1 text-text-whiteDisabled" />
                <Paragraph size="300" color="text-textIcon-blackSecondary">
                  RfQ
                </Paragraph>
              </div>
            </Tooltip>
          ) : (
            <Paragraph size="200">{OrderReqService.getTotalGrossAmount(order)}</Paragraph>
          )}
        </div>
      </td>
      <td className="h-6.5 pr-3 rounded-r-lg border-y-1 border-r-1">
        <div className="flex flex-row space-x-4">
          {order.status === "DRAFT_CREATED" && (
            <Dropdown hAlignTo="end" vAlignTo="bottom" items={dropdownMenuItems} stopPropagation>
              <IconButton
                size="large"
                variant="secondary"
                shape="square"
                label="Actions"
                Icon={DotsVerticalIcon}
                isControlled
              />
            </Dropdown>
          )}
          {attachments.length > 0 && (
            <PaperClipIcon
              className="w-5 text-textIcon-blackSecondary"
              data-testid="ordersTable-itemColumn-attachment"
            />
          )}
        </div>
      </td>
    </tr>
  );
};
