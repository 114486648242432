import { DefaultError, useMutation } from "@tanstack/react-query";
import { useFlag } from "@unleash/proxy-client-react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { OrderContextMenuActions } from "@web/common";
import { DeleteOrderDraftModal, OrderChanges, ProductItemsTable } from "@web/common/components";
import { CancelOrderModal } from "@web/common/components/modals/CancelOrderModal";
import { CancelSuccessModal } from "@web/common/components/modals/CancelSuccessModal";
import { useModalContext } from "@web/common/contexts/ModalContext";
import { LEGACY_OrderLayout } from "@web/common/layouts";
import { OrderRequisition } from "@web/common/network/model";
import { OrderReqService } from "@web/common/services/OrderRequisition";
import { Heading, Label, Modal, TabItem, Tabs } from "@web/ui";
import { triggerFileDownload } from "@web/utils";

import { cancelOrder } from "src/api/cancelOrder";
import { deleteDraft } from "src/api/deleteDraft";
import { exportOrderToExcel } from "src/api/exportOrderToExcel";
import { exportOrderToMtml } from "src/api/exportOrderToMtml";
import { ContinueWithDraftButton } from "src/components/DraftInfo/ContinueWithDraftButton";
import { CloseOrderModal } from "src/components/modals/CloseOrderModal";
import routes from "src/config/routes";
import { useCloseOrder } from "src/hooks/useCloseOrder";
import { useSystemMessage } from "src/hooks/useSystemMessage";
import { convertOrderCatalogItemToProductItem } from "src/services/LocalOrderReqService";
import { ApiResponse } from "src/store";
import { AppOrderRequisition } from "src/typegens";

type Props = {
  order: OrderRequisition;
  refetch: () => Promise<unknown>;
};

export const OrderDetails = ({ order, refetch }: Props) => {
  const cancelOrderMutation = useMutation<
    ApiResponse<AppOrderRequisition>,
    DefaultError,
    { orderId: string }
  >({
    mutationKey: ["cancelOrder"],
    mutationFn: cancelOrder,
    onSuccess: async () => {
      await refetch();
      openModal(<CancelSuccessModal closeModal={closeModal} />);
    },
    onError: () => {
      setSystemMessage({
        type: "failure",
        message: "There was an issue while cancelling this order. Please try again.",
      });
    },
  });
  const { closeModal, openModal } = useModalContext();
  const { setSystemMessage } = useSystemMessage();
  const [isOrderClosureModalOpen, setIsOrderClosureModalOpen] = useState(false);
  const handleOpenOrderClosureModal = () => {
    setIsOrderClosureModalOpen(true);
  };
  const handleCloseOrderClosureModal = () => {
    setIsOrderClosureModalOpen(false);
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const hasSelectSupplierFeature = useFlag("select-supplier");

  const productItems = useMemo(
    () => order.items.map((item) => convertOrderCatalogItemToProductItem(item)),
    [order.items]
  );

  const unchangedProductItems = useMemo(
    () => order.unchangedItems.map((item) => convertOrderCatalogItemToProductItem(item)),
    [order.unchangedItems]
  );

  const openModalCancelOrder = () => {
    openModal(
      <CancelOrderModal
        closeModal={closeModal}
        onCancel={onOrderCancel}
        isLoading={cancelOrderMutation.isPending}
      />
    );
  };

  const onOrderCancel = async () => {
    cancelOrderMutation.mutate({ orderId: order.id });
  };

  // TODO #8836: Verify how the order closure should work - what to call in the API
  const closeOrderMutation = useCloseOrder({
    onSuccess: () => {
      handleCloseOrderClosureModal();
      setSystemMessage({
        type: "success",
        message: "The order was closed.",
      });
      refetch();
    },
    onError: () => {
      setSystemMessage({
        type: "failure",
        message: "There was an issue while closing this order. Please try again.",
      });
    },
  });

  const { mutate: downloadMTML } = useMutation({
    mutationKey: ["exportOrderToMtml"],
    mutationFn: () => exportOrderToMtml({ orderId: order.id }),
    onSuccess: (file) => {
      setSystemMessage({
        type: "success",
        message: "The PO will be downloaded soon.",
      });
      triggerFileDownload({
        file,
        fileNameWithExtension: `S2S_${order.id}.xml`,
      });
    },
    onError: () => {
      setSystemMessage({
        type: "failure",
        message: "There was an issue while downloading the PO. Please try again.",
      });
    },
  });

  const { mutate: downloadExcel } = useMutation({
    mutationKey: ["exportOrderToExcel"],
    mutationFn: async () =>
      exportOrderToExcel({
        orderId: order.id,
      }),

    onSuccess: (file) => {
      setSystemMessage({
        type: "success",
        message: "The PO will be downloaded soon.",
      });
      triggerFileDownload({
        file,
        fileNameWithExtension: `S2S_${order.id}.xlsx`,
      });
    },
    onError: () => {
      setSystemMessage({
        type: "failure",
        message: "There was an issue while downloading the PO. Please try again.",
      });
    },
  });

  const handleOrderClosure = () => {
    closeOrderMutation.mutate({ orderId: order.id });
  };

  const openDeleteDraftModal = (draftId?: string) => {
    if (!draftId) {
      return;
    }
    openModal(
      <DeleteOrderDraftModal
        closeModal={closeModal}
        draftId={draftId}
        onConfirm={() => {
          deleteDraft(draftId).then(() => navigate(routes.orders));
          closeModal();
        }}
      />
    );
  };

  const handleDeleteDraft = () => {
    openDeleteDraftModal(order.id);
  };

  const contextMenuActions: OrderContextMenuActions = {
    onMTMLDownload: downloadMTML,
    onExcelDownload: downloadExcel,
    openModalCancelOrder,
    openModalCloseOrder: handleOpenOrderClosureModal,
    onDeleteDraft: handleDeleteDraft,
  };

  const orderHasChanges = order.changes && order.changes.length > 0;

  const tabItems: TabItem[] = useMemo(
    () => [
      {
        id: "FINAL_ITEMS",
        label: "Final Items",
        children: <ProductItemsTable productItems={productItems} className="mt-3" />,
      },
      {
        id: "CHANGES",
        label: "Changes",
        children: (
          <OrderChanges
            allowedChangeTypes={order.changeTypeList}
            unchangedItems={unchangedProductItems}
            changedItems={order.changes || []}
          />
        ),
      },
    ],
    [order.changeTypeList, order.changes, productItems, unchangedProductItems]
  );

  return (
    <>
      <Modal isOpen={isOrderClosureModalOpen} closeModal={handleCloseOrderClosureModal}>
        <CloseOrderModal
          isLoading={closeOrderMutation.isPending}
          onCloseModal={handleCloseOrderClosureModal}
          onCloseOrder={handleOrderClosure}
        />
      </Modal>
      <LEGACY_OrderLayout
        order={order}
        contextMenuActions={contextMenuActions}
        goBackRoute={routes.orders}
        topbarButtons={
          order.status === "DRAFT_CREATED" && !hasSelectSupplierFeature ? (
            <ContinueWithDraftButton order={order} />
          ) : null
        }
      >
        <Heading size="100">{order.subject}</Heading>
        <hr className="my-4" />
        <div className="flex flex-row w-full items-start justify-between">
          <Heading size="300">{t("pages.orderDetails.orderedItems")}</Heading>
          <div className="flex flex-row items-center">
            <Label size="200">{t("pages.orderDetails.totalGrossAmount")}</Label>
            <Heading size="300" className="pl-5">
              {OrderReqService.getTotalGrossAmount(order)}
            </Heading>
          </div>
        </div>
        {orderHasChanges ? (
          <Tabs
            className="mt-3"
            size="large"
            variant="line"
            items={tabItems}
            initialTabId="FINAL_ITEMS"
          />
        ) : (
          <ProductItemsTable productItems={productItems} />
        )}
      </LEGACY_OrderLayout>
    </>
  );
};
