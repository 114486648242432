import { PencilIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { MouseEventHandler, useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation, useNavigate } from "react-router-dom";

import { IconButton, RegularButton, containerPadding } from "@web/ui";

interface Props {
  children: React.ReactNode;
  isGatherOutAvailable?: boolean;
  startGatherOut?: () => void;
  isGatherOutStarted?: boolean;
  goBackPath?: string;
}

const FormLayout = ({
  children,
  isGatherOutAvailable = false,
  startGatherOut,
  isGatherOutStarted = false,
  goBackPath,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location.pathname, location.search]);

  const handleNavigateBack: MouseEventHandler<HTMLButtonElement> = () => {
    if (goBackPath) {
      navigate(goBackPath);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className={`${containerPadding} flex justify-between py-4`}>
        <IconButton
          size="large"
          variant="secondary"
          shape="circle"
          label="Go back"
          Icon={XIcon}
          onClick={handleNavigateBack}
          disabled={isGatherOutStarted}
        />
        {isGatherOutAvailable && !!startGatherOut && (
          <RegularButton
            variant="secondary"
            size="large"
            label="Edit items"
            LeadingIcon={PencilIcon}
            onClick={startGatherOut}
            loading={isGatherOutStarted}
          />
        )}
      </div>
      <div className="flex-grow bg-neutral_100">
        <div className={`${containerPadding} flex pt-8`}>{children}</div>
      </div>
    </div>
  );
};

export default FormLayout;
